import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import Layout from '../components/Layout/Layout'

const ns = `four-o-four-page`

const FourOFourPage = () => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <Layout>
      <div className={rootClassnames}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className={`${ns}__title text-center`}>404</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-9">
              <p>
                We couldn’t find the page you were looking for. Check that you
                typed the address correctly, go back to the homepage or let us
                know what you were hoping to find.
              </p>
              <div className={`${ns}__links`}>
                <Link to="/">Back to Home</Link>
                <Link to="/about/#contact">Ask a question</Link>
                <Link to="/visit">Visit the museum</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FourOFourPage
